body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    height: 100%;
}

.responsive-image {
    max-width: 300px;
    height: auto;
    margin: auto;
}


.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    text-align: center;
}

.content {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 10px;
    padding: 20px;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card {
    background: transparent;
    border: none;
    box-shadow: none;
}

.card-content {
    margin-bottom: 20px;
}

.card-title {
    font-size: 2rem;
    margin-bottom: 20px;
}

.flow-text {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.card-action {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.btn {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.btn:hover {
    background-color: #0056b3;
}


@media (max-width: 600px) {
    .content {
        padding: 15px;
    }

    .card-title {
        font-size: 1.5rem;
    }

    .flow-text {
        font-size: 1rem;
    }

    .btn {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}


.player-1 { background-color: #ff6f61; }
.player-2 { background-color: #6b5b95; }
.player-3 { background-color: #88b04b; }
.player-4 { background-color: #d65076; }
.player-5 { background-color: #45a29e; }
.player-6 { background-color: #ff7b25; }
.player-7 { background-color: #034f84; }
.player-8 { background-color: #f7cac9; }


